import { EPolicyType, IInsurerStepModel, TDateISODate } from "../types";

export const POLICY_EMPTY_MODEL: IInsurerStepModel["current_policy"] = {
  insurer_name: "",
  number: "",
  date_end: "" as TDateISODate,
  date_start: "" as TDateISODate,
  policy_type: EPolicyType.SERVICE,
  power_unit_count: null,
};
