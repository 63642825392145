import type { IMapper } from "~/types";
import { INaicsCodeItem, INaicsCodeItemResponseModel } from "../../types";

export const naicsCodeItemMapper: IMapper<
  INaicsCodeItemResponseModel,
  INaicsCodeItem
> = (item) => {
  return {
    label: item.value,
    value: item.key,
  };
};

export const naicsCodeItemListMapper: IMapper<
  INaicsCodeItemResponseModel[],
  INaicsCodeItem[]
> = (value: INaicsCodeItemResponseModel[]) => value.map(naicsCodeItemMapper);
