import { IInsurerStepModel } from "../types";

export const CUSTOMER_EMPTY_MODEL: IInsurerStepModel["customer"] = {
  name: "",
  usdot: "",
  fein: "",
  naics_code: "",
  contact: {
    phone: "",
    name: "",
    email: "",
  },
};
