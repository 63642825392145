import { useStateFeature } from "./features/state";
import { useNaicsCodeFeature } from "./features/naics";

export const onboardingStateStoreInstall = () => {
  const stateFeature = useStateFeature();
  const naicsCodeFeature = useNaicsCodeFeature();

  return {
    ...stateFeature,
    ...naicsCodeFeature,
  };
};
