import { ref } from "vue";
import { INaicsCodeItem } from "@/modules/help-center";
import { getNaicsCodes } from "@/modules/onboarding/services";

export const useNaicsCodeFeature = () => {
  const naicsCodes = ref<INaicsCodeItem[]>([]);

  const setNaicsCodes = async () => {
    naicsCodes.value = await getNaicsCodes();
  };

  return {
    naicsCodes,
    setNaicsCodes,
  };
};
