import { API_SERVICE_INJECTION_KEY } from "@/constants";
import { injector } from "@/services";
import {
  IOnboardingAppConfig,
  IOnboardingAppConfigResponseModel,
  IOnboardingModel,
  IOnboardingModelResponseModel,
  IOnboardingSession,
  IOnboardingSessionResponseModel,
  IInsurerStepModel,
  IInsurerStepModelResponseModel,
  IPlatform,
  ITelematicsStepModel,
  ITelematicsStepResponseModel,
  IConnectionCheck,
  IConnectionCheckResponseModel,
  IOnboardingComplete,
} from "../../types";
import {
  onboardingAppConfigMapper,
  onboardingModelMapper,
  onboardingRenderModelMapper,
  onboardingSessionMapper,
} from "../mapping";
import { PLATFORMS } from "../../constants";
import { IRenderConfig, IRenderConfigResponseModel } from "~/types";

const publicBasePath = "/api/v2";
const privateBasePath = "/api/v1";

export const getOnboardingConfig = async () => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: IOnboardingAppConfig | null = null;
  const response = await apiService.get<IOnboardingAppConfigResponseModel[]>(
    `${publicBasePath}/app-config`
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    result = onboardingAppConfigMapper(response.data[0]);
  }
  return result;
};

export const getSession = async (token: string) => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: IOnboardingSession | null = null;
  const response = await apiService.get<IOnboardingSessionResponseModel[]>(
    `${publicBasePath}/session/${token}`
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    result = onboardingSessionMapper(response.data[0]);
  }
  return result;
};

export const getOnboardingModel = async () => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: IOnboardingModel | null = null;
  const response = await apiService.get<IOnboardingModelResponseModel[]>(
    `${privateBasePath}/onboarding-model`
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    result = onboardingModelMapper(response.data[0]);
  }
  return result;
};

export const getPlatforms = async () => {
  // TODO: revert this method after BE will be ready
  // const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: IPlatform[] | null = null;
  // const response = await apiService.get<IPlatformResponseModel[]>(
  //   `${privateBasePath}/platforms`
  // );
  // if (apiService.isRequestSuccess(response) && response.data) {
  //   result = platformsMapper(response.data);
  // }
  result = PLATFORMS;
  return result;
};

export const postInsurerStep = async (config: IInsurerStepModel) => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: IInsurerStepModelResponseModel | null = null;
  const response = await apiService.post<
    IInsurerStepModel,
    IInsurerStepModelResponseModel[]
  >(`${privateBasePath}/onboarding-insurer`, config);
  if (apiService.isRequestSuccess(response) && response.data) {
    result = response.data[0];
  }
  return result;
};

export const postTelematicsStep = async (config: ITelematicsStepModel[]) => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: ITelematicsStepResponseModel[] | null = null;
  const response = await apiService.post<
    ITelematicsStepModel[],
    ITelematicsStepResponseModel[][] | null
  >(`${privateBasePath}/onboarding-telematics`, config);
  if (apiService.isRequestSuccess(response) && response.data) {
    result = response.data[0];
  }
  return result;
};

export const postConnectionCheck = async (config: IConnectionCheck) => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: IConnectionCheckResponseModel | null = null;
  const response = await apiService.post<
    IConnectionCheck,
    IConnectionCheckResponseModel[]
  >(`${privateBasePath}/connection-check`, config);
  if (apiService.isRequestSuccess(response) && response.data) {
    result = response.data[0];
  }
  return result;
};

export const postOnboardingComplete = async (config: IOnboardingComplete) => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: IOnboardingModel | null = null;
  const response = await apiService.post<
    IOnboardingComplete,
    IOnboardingModel[]
  >(`${privateBasePath}/onboarding-complete`, config);
  if (apiService.isRequestSuccess(response) && response.data) {
    result = response.data[0];
  }
  return result;
};

export const postActivity = async (config: unknown) => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: unknown | null = null;
  const response = await apiService.post<unknown, unknown[]>(
    `${privateBasePath}/activity`,
    config
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    result = response.data[0];
  }
  return result;
};

export const getOnboardingRenderModel = async () => {
  const apiService = injector.injectFromRoot(API_SERVICE_INJECTION_KEY);
  let result: IRenderConfig | null = null;
  const response = await apiService.get<IRenderConfigResponseModel[]>(
    `${privateBasePath}/customer-app-config`
  );
  if (apiService.isRequestSuccess(response) && response.data) {
    result = onboardingRenderModelMapper(response.data[0]);
  }
  return result;
};
